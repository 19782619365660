import GenericHeader from "./GenericHeader";
import { Box } from "@mui/material";
import errorClasses from "../styles/Error.module.css";
import { useDispatch, useSelector } from "react-redux";
import ReferenceNumber from "./ReferenceNumber";
import { useRefreshAccessTokenMutation } from "../api/refreshSlice";

import { useEffect } from "react";
import {
  clearAnswers,
  clearApplicationInfo,
  clearInputIdList,
  setAccessToken,
  setErrorMessage,
  setIsAutoLogOut,
  setIsError,
  setIsLoading,
} from "../actions";
import Loading from "./Loading";

function Error(props) {
  const dispatch = useDispatch();
  const appInfo = useSelector((state) => state.appInfo);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const error = useSelector((state) => state.error);

  const [
    refreshAccessToken,
    {
      data,
      isSuccess,
      isError: isRefreshError,
      error: refreshError,
      isLoading,
    },
  ] = useRefreshAccessTokenMutation();

  useEffect(() => {
    console.log("Error:", error);
    try {
      if ("data" in error.errorMessage) {
        if ("code" in error.errorMessage.data) {
          if (error.errorMessage.data.code === "token_not_valid") {
            console.log("fetching new token...");
            dispatch(setIsLoading(true));
            refreshAccessToken(appInfo.token.refresh)
              .then((tokenData) => {
                if ("error" in tokenData) {
                  // assume for now error means our refresh token has expired,
                  // clear out state and return to startup screen for fresh login.
                  // For some reason this error is not being caught by catch block,
                  // so handling it here instead
                  props.setStage("startup");
                  dispatch(setIsError(false));
                  dispatch(setIsLoading(false));
                  dispatch(setIsAutoLogOut(true));
                  dispatch(clearAnswers());
                  dispatch(clearInputIdList());
                  dispatch(clearApplicationInfo());
                } else {
                  dispatch(setAccessToken(tokenData.data.access));
                  dispatch(setIsError(false));
                  dispatch(setIsLoading(false));
                }
              })
              .catch((tokenError) => {
                // repeating this workflow as insurance
                props.setStage("startup");
                dispatch(setIsError(false));
                dispatch(setIsLoading(false));
                dispatch(setIsAutoLogOut(true));
                dispatch(clearAnswers());
                dispatch(clearInputIdList());
                dispatch(clearApplicationInfo());
              });
          }
        }
      }
    } catch (e) {
      // catching any unexpected issues with error message formatting
      console.log(e);
    }
  }, [props, error, appInfo, refreshAccessToken, data, isSuccess, dispatch]);

  useEffect(() => {
    if (isRefreshError) {
      console.loe("refreshError:", refreshError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(refreshError));
    }
  }, [isRefreshError, refreshError, dispatch]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box className={errorClasses.errorContainer} component="div">
          <GenericHeader title="" />
          <h1>Sorry!</h1>
          <p>We've encountered some kind of error:</p>
          <p>
            Error {error.errorMessage.data?.email[0]}
            {/* {error.errorMessage.status}{" "} */}
          </p>
          <p>{error.errorMessage.error}</p>
          {isLoggedIn ? (
            <div className={errorClasses.refreshBox}>
              <p>
                Please refresh this page and click "Continue Existing
                Application" to resume with your Application ID/Case Number and
                PIN:
              </p>
              <ReferenceNumber />
            </div>
          ) : (
            <p>Please refresh this page to try again.</p>
          )}
        </Box>
      )}
    </>
  );
}

export default Error;

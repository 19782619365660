import classes from "../styles/Thanks.module.css";
import buttonClasses from "../styles/Button.module.css";
import ApplicationSubmittedIcon from "../assets/images/submitted.svg";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearAnswers, clearApplicationInfo, resetProgress } from "../actions";

function Thanks(props) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(clearAnswers());
    dispatch(resetProgress());
    dispatch(clearApplicationInfo());
    props.setStage("startup");
  }

  return (
    <div className={classes.thanks}>
      <h1>Thank You!</h1>
      <div className="submitted-icon">
        <img alt="check-mark" src={ApplicationSubmittedIcon} />
      </div>
      <p>
        Please return here at any time to see the status of your application or
        for a list of additional resources.
      </p>
      <Button
        className={buttonClasses.button}
        size="large"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Exit
      </Button>
    </div>
  );
}

export default Thanks;

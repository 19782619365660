import { Box, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import classes from '../styles/Menu.module.css';

function MenuButton(props) {

    const icon = function(title) {
        switch(title) {
            case "Application Status":
                return <BeenhereOutlinedIcon />;
            case "Application Reference":
                return <BookmarkIcon />;
            default:
                return <HelpOutlineIcon />
        }
    }

    return ( 
        <Box onClick={props.onClick} className={classes.menuButton}>
            <IconButton color="primary">
                {icon(props.title)}
            </IconButton>
            <p>{props.title}</p>
            <IconButton className={classes.chevron}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
     );
}

export default MenuButton;
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toggleMenu } from '../actions';
import supportClasses from '../styles/Support.module.css';
import miscClasses from '../styles/Misc.module.css';
import buttonClasses from '../styles/Button.module.css'

function Support() {
  
  /**
   * TODO: Currently there are, to my knowledge, no API routes related to submitting support requests.
   * These will need to be created on the backend and integrated with the form below, which currently does nothing.
   */

    const dispatch = useDispatch();

    const [helpType, setHelpType] = useState('')
    const [helpRequest, updateHelpRequest] = useState('')

    function handleSelectChange(e) {
        setHelpType(e.target.value)
    }

    function handleSubmit() {
        console.log(`Submitting request for ${helpType} help...`);
        console.log(helpRequest);
        dispatch(toggleMenu());
    }

    return ( 
        <div className={supportClasses.support}>
        <FormControl className={miscClasses.marginSmall}>
          <InputLabel id="support_select_label">Help Type</InputLabel>
          <Select
            labelId="support_select_label"
            id="support_select"
            value={helpType}
            label="help type"
            placeholder='choose a help type'
            onChange={handleSelectChange}
          >
            <MenuItem value={'application_status'}>Application Status</MenuItem>
            <MenuItem value={'bugs'}>Bugs and Technical Problems</MenuItem>
            <MenuItem value={'personnel'}>Personnel Issue</MenuItem>
            <MenuItem value={'other'}>Other Issue</MenuItem>
          </Select>
        </FormControl>
        <TextField 
            className={miscClasses.marginSmall}
            multiline={true}
            placeholder="Add your message..."
            onChange={function(e){updateHelpRequest(e.target.value)}}
            rows={8}
        />
        <Button
            className={buttonClasses.button} 
            onClick={handleSubmit}
            variant="contained"
            >
                Submit
        </Button>
      </div>
     );
}

export default Support;
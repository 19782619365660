import { Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import classes from "../styles/Rejection.module.css";
import buttonClasses from "../styles/Button.module.css";

function RejectionMessage(props) {
  return (
    <div className={classes.rejection}>
      <div className={classes.rejectionTop}>
        <ErrorOutlineIcon color="warning" />
        <p>You answered "No"</p>
      </div>
      <p>
        Sorry, only damage directly related to the storm will be considered to
        receive repair services from Recover Hampton Roads.
      </p>
      <Button
        target="_top"
        rel="noopener noreferrer"
        href={`mailto:support@cima.org`}
        variant="contained"
        color="success"
        className={buttonClasses.buttonColorAgnostic}
      >
        Contact Support
      </Button>
    </div>
  );
}

export default RejectionMessage;

import { useEffect, useState } from "react";
import { FormControl, RadioGroup, FormLabel } from "@mui/material";
import classes from "../styles/RadioButtonGroup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateAnswers, removeInputIdFromList } from "../actions";

function RadioButtonGroup(props) {
  useEffect(() => {
    setInitialButtonState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const appId = useSelector((state) => state.appInfo.application.id);

  //const currentStepAnswers = useSelector(state => state.answers);
  // const isRejected = useSelector((state) => state.isRejected);

  const [selected, setSelected] = useState("");

  function setInitialButtonState() {
    const final = isAnswerEmpty(props.questions[0].answers[0])
      ? null
      : props.questions[0].answers[0].value;
    setSelected(final);

    function isAnswerEmpty(obj) {
      if (obj === undefined) {
        return true;
      }
      return Object.keys(obj).length === 0;
    }
  }

  let answerObject = {
    application: appId,
    question: props.questions[0].id,
    value: "",
  };

  const handleRadioChange = (event) => {
    const clickedChoice = event.target.value;
    setSelected(clickedChoice);
    answerObject.value = clickedChoice;
    dispatch(updateAnswers(answerObject));
    dispatch(removeInputIdFromList(props.questions[0].id.toString()));
    // props.questions[0].choices.forEach((choice) => {
    //   if (
    //     choice === clickedChoice &&
    //     !props.questions[0].id.includes[(20, 21, 22, 23, 24)]
    //   ) {
    //     if (clickedChoice?.toUpperCase() === "NO") {
    //       dispatch(flagRejected(true));
    //     } else {
    //       dispatch(flagRejected(false));
    //       if (isRejected) {
    //         props.toggleRejectionMessage((current) => false);
    //       }
    //     }
    //   }
    // });
  };

  return (
    <FormControl
      sx={{
        margin: "1rem",
      }}
    >
      <FormLabel>{props.group_title}</FormLabel>
      <RadioGroup>
        {props.questions[0].choices.map((choice) => {
          return (
            <div
              className={`${classes.radioWrapper} ${
                selected === choice ? classes.selected : ""
              }`}
              key={choice}
            >
              <input
                required
                type="radio"
                value={choice}
                checked={selected === choice}
                onChange={handleRadioChange}
                id={`${props.questions[0].id}_${choice}`}
              />
              <label htmlFor={`${props.questions[0].id}_${choice}`}>
                {choice?.toUpperCase()}
              </label>
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonGroup;

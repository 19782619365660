import "../styles/ApplicationAgreement.css";
import { Button } from "@mui/material";
import { useState } from "react";

const ApplicationAgreement = (props) => {
  const [clicked, setClicked] = useState("");

  function handleAgreement(choice) {
    choice ? setClicked("yes") : setClicked("no");
    props.updateAllowContinue(choice);
  }

  return (
    <div className="ApplicationAgreement">
      <div className="header">Application Agreement</div>
      <div className="text">
        Completion of the application and screening processes does not assure
        repairs will be made nor does it obligate us to provide services. We do
        not guarantee material or labor. Any services provided or scheduled may
        depend upon availability and are subject to change without notice.
      </div>

      <div className="card">
        <div className="app-question">Do you agree to the above statement?</div>
        <div className="buttons">
          <Button
            variant={clicked === "yes" ? "contained" : "outlined"}
            size="large"
            className={`${"application-buttons"} ${
              clicked === "yes" ? "selected" : ""
            }`}
            onClick={function () {
              handleAgreement(true);
            }}
          >
            Yes
          </Button>
          <Button
            variant={clicked === "no" ? "contained" : "outlined"}
            size="large"
            className={`${"application-buttons"} ${
              clicked === "no" ? "selected" : ""
            }`}
            onClick={function () {
              handleAgreement(false);
            }}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationAgreement;

import { Box, Button, TextField } from "@mui/material";
import buttonClasses from "../styles/Button.module.css";
import resumeClasses from "../styles/ResumeExisting.module.css";
import { useResumeApplicationMutation } from "../api/applicationInfoSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setApplicationInfo, toggleLogIn } from "../actions";
import GenericHeader from "./GenericHeader";

function ResumeExisting(props) {
  const dispatch = useDispatch();

  const [
    resumeApplication,
    { data, isSuccess, isError: isResumeError, error: resumeError },
  ] = useResumeApplicationMutation();

  const [appId, setAppId] = useState("");
  const [pin, setPin] = useState("");

  function handleChange(e) {
    e.preventDefault();
    if (e.target.id === "application-id") {
      e.target.value = e.target.value?.toUpperCase();
      setAppId(e.target.value);
    } else if (e.target.id === "pin-number") {
      setPin(e.target.value);
    }
  }

  function handleClick() {
    let idAndPin = {
      application_id: appId,
      pin: pin,
    };
    resumeApplication(idAndPin);
  }

  useEffect(() => {
    if (isSuccess) {
      console.log("Resuming existing application:", data.application);
      dispatch(setApplicationInfo(data));
      dispatch(toggleLogIn());
      props.setStage("screener_application");
    }
  }, [isSuccess, data, props, dispatch]);

  useEffect(() => {
    if (isResumeError) {
      console.error("Error resuming existing application:", resumeError);
    }
  }, [isResumeError, resumeError, dispatch]);

  return (
    <Box>
      <GenericHeader title="Login" setStage={props.setStage} isResume={true} />
      <div className={resumeClasses.wrapper}>
        <div>
          <p className={resumeClasses.resumeText}>
            Continue or check status of an existing application
          </p>
          <Box className={buttonClasses.buttonContainer}>
            <TextField
              className={resumeClasses.inputField}
              label="Application ID"
              id="application-id"
              onChange={handleChange}
              aria-describedby="application-id"
            />
            <TextField
              className={resumeClasses.inputField}
              label="PIN Number"
              id="pin-number"
              type="number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={handleChange}
              aria-describedby="pin-number"
            />
            {isResumeError && (
              <p className={resumeClasses.errorMessage}>
                Please enter correct ID and PIN combination.
              </p>
            )}{" "}
            {/* display error message if there's an error */}
          </Box>
        </div>

        <div
          className={`${buttonClasses.buttonContainer} ${buttonClasses.containerWithTopMargin}`}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!appId.length || !pin.length}
            className={buttonClasses.button}
            onClick={handleClick}
          >
            Login
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default ResumeExisting;

import { apiSlice } from "./apiSlice";

export const photoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitPhoto: builder.mutation({
      query: (formData) => ({
        url: "/v1/applications/answermediav1/",
        method: "POST",
        body: formData,
      }),
    }),
    deletePhoto: builder.mutation({
      query: (id) => ({
        url: `/v1/applications/damage_assessment/media/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useSubmitPhotoMutation, useDeletePhotoMutation } = photoSlice;

/* IGNORE: old dummy data
const fakeMilestones = [
  {
    "id": 1,
    "screen_count": 9,
    "step_title": "Survey Questions",
    "step_desc": null,
    "step_parameter": "survey_questions",
    "step_number": 1,
    "question_module": 1
},
{
    "id": 2,
    "screen_count": 4,
    "step_title": "Address and Contact Info",
    "step_desc": null,
    "step_parameter": "address_and_contact_info",
    "step_number": 2,
    "question_module": 1
},
{
    "id": 4,
    "screen_count": 3,
    "step_title": "Damage Assessment",
    "step_desc": null,
    "step_parameter": "damage_assessment",
    "step_number": null,
    "question_module": 1
},
{
    "id": 5,
    "screen_count": 4,
    "step_title": "Authorize and Submit",
    "step_desc": null,
    "step_parameter": "authorize_and_submit",
    "step_number": null,
    "question_module": 1
},
{
    "id": 3,
    "screen_count": 4,
    "step_title": "Household Information",
    "step_desc": null,
    "step_parameter": "household_information",
    "step_number": null,
    "question_module": 1
}
]
*/

const milestonesReferenceReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_MILESTONES_REFERENCE":
      return action.payload;
    case "CLEAR_MILESTONES_REFERENCE":
      return [];
    default:
      return state;
  }
};

export default milestonesReferenceReducer;

import { produce } from 'immer';

const initialState = {
    totalMilestones: 0,
    currentMilestoneIndex: 0,
    totalScreensInMilestone: 0,
    currentScreen: 1,
    totalScreenProgress: 0,
    applicationComplete: false,
    completedApplicationStatus: 'review'
  }

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case "INCREMENT_MILESTONE_PROGRESS":
            return produce(state, draft => {
                draft.currentScreen = 1;
                draft.currentMilestoneIndex = draft.currentMilestoneIndex + 1;
                draft.totalScreenProgress = draft.totalScreenProgress + 1;
            })
        case "DECREMENT_MILESTONE_PROGRESS":
            return produce(state, draft => {
                draft.currentMilestoneIndex = draft.currentMilestoneIndex - 1;
                draft.currentScreen = action.payload;
            })
        case "INCREMENT_SCREEN_PROGRESS":
            console.log("incrementing screen progress...")
            return produce(state, draft => {
                draft.currentScreen = draft.currentScreen + 1;
                draft.totalScreenProgress = draft.totalScreenProgress + action.payload
            })
        case "DECREMENT_SCREEN_PROGRESS":
            return produce(state, draft => {
                draft.currentScreen = draft.currentScreen - 1;
                draft.totalScreenProgress = draft.totalScreenProgress - action.payload
            })
        case "SET_TOTAL_MILESTONE_COUNT":
            return produce(state, draft => {
                draft.totalMilestones = action.payload;
            })
        case "SET_SCREENS_IN_MILESTONE_COUNT":
            return produce(state, draft => {
                draft.totalScreensInMilestone = action.payload;
            })
        case "SET_CURRENT_MILESTONE_INDEX":
            return produce(state, draft => {
                draft.currentMilestoneIndex = action.payload
            })
        case "SET_CURRENT_SCREEN":
            return produce(state, draft => {
                draft.currentScreen = action.payload
            })
        case "RESET_PROGRESS":
            return initialState;
        default:
            return state;
    }
}

export default progressReducer;
import classes from '../styles/TextInputGroup.module.css'

function PlainText(props) {
    return ( 
        <div className={classes.group}>
        <p>{props.group_title}</p>
        </div>
     );
}

export default PlainText;
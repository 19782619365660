import { useState } from "react";
import { Box } from "@mui/material";
import menuClasses from "../styles/Menu.module.css";
import GenericHeader from "./GenericHeader.js";
import MenuButton from "./MenuButton.js";
import ApplicationStatus from "./ApplicationStatus";
import ApplicationReference from "./ApplicationReference";
import Support from "./Support";

function Menu(props) {
  const [menuToDisplay, setMenuToDisplay] = useState("Menu");

  function selectMenu(menu) {
    setMenuToDisplay(menu);
  }

  const menuContent = function () {
    switch (menuToDisplay) {
      case "Menu":
        return (
          <Box component="div">
            <MenuButton
              title="Application Reference"
              onClick={function () {
                selectMenu("Application Reference");
              }}
            />
            <MenuButton
              title="Application Status"
              onClick={function () {
                selectMenu("Application Status");
              }}
            />
            <MenuButton
              title="Help and Support"
              onClick={function () {
                selectMenu("Help and Support");
              }}
            />
          </Box>
        );
      case "Help and Support":
        return (
          <Box component="div">
            <Support />
          </Box>
        );
      case "Application Status":
        return <ApplicationStatus />;
      case "Application Reference":
        return <ApplicationReference stage={props.stage} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Box className={menuClasses.menu}>
      <GenericHeader isMenu={true} title={menuToDisplay} />
      {menuContent()}
    </Box>
  );
}

export default Menu;

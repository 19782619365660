import { apiSlice } from "./apiSlice";


export const enteredItemsSlice = apiSlice.injectEndpoints({
    reducerPath: 'enteredItems',
    endpoints: builder => ({
        getEnteredItems: builder.query({
            query: ({appId, group_name}) => `/v1/applications/answersv1/?application_id=${appId}&screen_group_name=${group_name}`
        }),
        deleteItem: builder.mutation({
            query: (id) => {
                return {
                    url: `/v1/applications/people/${id}/`,
                    method: 'DELETE'
                }
            }
        })
    })
})

export const {
    useGetEnteredItemsQuery,
    useDeleteItemMutation
} = enteredItemsSlice;
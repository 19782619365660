import React from "react";
import { useSelector } from "react-redux";
import Tick from "../assets/images/tick.svg";
import "../styles/ApplicationStatus.css";

const ApplicationStatus = () => {

  const milestones = useSelector(state => state.milestonesReference);
  const progress = useSelector(state => state.progress);

  function Completed(step, idx, isApplication) {
    return <span id={idx.toString()} key={idx.toString()}>
              {isApplication ? `Step ${idx  + 1}: ` : ''}{step.step_title}
              <img src={Tick} alt="check-mark" className="tick" />
            </span>
  }

  function InProgress(step, idx, isApplication) {
    return <span className="bold" id={idx.toString()} key={idx.toString()}>
            {isApplication ? `Step ${idx  + 1}: ` : ''}{step.step_title}
          </span>
  }

  function ToDo(step, idx, isApplication) {
    return <span id={idx.toString()} key={idx.toString()}>
              {isApplication ? `Step ${idx  + 1}: ` : ''}{step.step_title}
            </span>
  }

  function generateStepList() {
    if(progress.applicationComplete) {
      const steps = [
        {step_title: "Application Submitted", short: 'submitted'}, 
        {step_title: "Application Review", short: 'review'}, 
        {step_title: "Decision and Followup", short: 'decision'}
      ]
      let currentStepIdx = steps.findIndex(elem => elem.short === progress.completedApplicationStatus)
      return steps.map((step, idx) => {
        if(idx < currentStepIdx) {
          return Completed(step, idx)
        } else if(idx === currentStepIdx) {
          return InProgress(step, idx);
        } else {
          return ToDo(step, idx);
        }

      })
    } else {
      if(milestones.length) {
        return milestones.map((step, idx) => {
          if(idx < progress.currentMilestoneIndex) {
              return Completed(step, idx, true);
          } else if(idx === progress.currentMilestoneIndex) {
              return InProgress(step, idx, true)
          } else {
              return ToDo(step, idx, true);
          }
        })
      } else {
        return <p>Once you begin your application, check here to review your status.</p>
      }
    }
  }

  const stepList = generateStepList();

  return (
    <div className="ApplicationStatus">
      <div className="status-header">Application Status</div>
      <div className="application-steps">
        {stepList}
      </div>
    </div>
  );
};

export default ApplicationStatus;

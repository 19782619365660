import "../styles/AuthorizeVisit.css";
import RadioButtonGroup from "./RadioButtonGroup";
import TextInputGroup from "./TextInputGroup";

const AuthorizeVisit = (props) => {

  return (
    <div className="AuthorizeVisit">
      <div className="text visit-text">
        I authorize Recover Hampton Roads, its affiliates, its partners, and/or
        its representatives to visit the property. The purpose of this visit(s)
        is to view and assess the damage, to record images, and gather
        information. This may require passing onto the property, traversing
        damaged portions of the home, and entry into secured and unsecured parts
        of the structure, both below and above the primary living areas. The
        homeowner or members of the household will not hold said visitors liable
        for, in whole or in part, to damage incurred from such activities.
        <br />
        <br />
        Bysubmitting this application, I authorize that I am 18 years of age or
        older and consent to use of these services.
      </div>

      <div className="authorize-visit-card">
        <span className="display">
          <RadioButtonGroup 
              groupIndex={props.question_groups[0].id.toString()}
              group_title={props.question_groups[0].group_title}
              questions={props.question_groups[0].questions}
          />
        </span>
        <span>
          <TextInputGroup 
              groupIndex={props.question_groups[1].id.toString()}
              group_title={props.question_groups[1].group_title}
              group_desc={props.question_groups[1].group_desc}
              questions={props.question_groups[1].questions}
              groupType={props.question_groups[1].group_type}
          />
        </span>
      </div>
    </div>
  );
};

export default AuthorizeVisit;

import { produce } from 'immer';

let initialState = {isError: false, errorMessage: {}};

const errorReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_IS_ERROR":
            return produce(state, draft => {
                draft.isError = action.payload;
            })
        case "SET_ERROR_MESSAGE":
            return produce(state, draft => {
                draft.errorMessage = action.payload;
            })
        default:
            return state;
    }
}

export default errorReducer;
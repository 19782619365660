import produce from "immer";

const applicationReducer = (state = {}, action) => {

    switch(action.type) {
        case "SET_APPLICATION_INFO":
            return action.payload;
        case "SET_ACCESS_TOKEN":
            return produce(state, draft => {
                draft.token.access = action.payload;
            });
        case "CLEAR_APPLICATION_INFO":
            return {};
        default:
            return state;
    }
}

export default applicationReducer;
import { produce } from 'immer';

const answersReducer = (state = [], action) => {
    switch (action.type) {
        case "UPDATE_ANSWERS":
            /*
            action.payload: {
                application: someId,
                question: anotherId,
                value: 'blah'
            }
            */
           return produce(state, draft => {
               const answerIndex = draft.findIndex(answer => answer.question === action.payload.question);
               if(answerIndex !== - 1){
                draft[answerIndex] = action.payload;
               } else {
                draft.push(action.payload);
               }
           })
        case "CLEAR_ANSWERS":
            return [];
        default:
            return state;
    }
}

export default answersReducer;
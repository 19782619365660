import buttonClasses from "../styles/Button.module.css";
import { useSubmitPhotoMutation } from "../api/photoSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementScreenProgress,
  setErrorMessage,
  setIsError,
  setIsLoading,
} from "../actions/index";
import { useEffect, useRef } from "react";
import { progressCalculator } from "../util/utilFunctions";

function PhotoUploadButton(props) {
  const appInfo = useSelector((state) => state.appInfo);
  const photoQuestionId = useSelector((state) => state.photoQuestionId);
  const totalMilestones = useSelector(
    (state) => state.progress.totalMilestones
  );
  const totalScreensInMilestone = useSelector(
    (state) => state.progress.totalScreensInMilestone
  );

  const [submitPhoto, { isError: isPhotoError, error: photoError }] =
    useSubmitPhotoMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPhotoError) {
      console.error("Error submitting photos...", photoError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(photoError));
    }
  }, [isPhotoError, photoError, dispatch]);

  const photoInput = useRef(null);

  function handlePhoto(e) {
    console.log("submitting photo...");
    e.preventDefault();
    let photo = photoInput.current.files[0];
    let formData = new FormData();
    formData.append("file", photo);
    formData.append("question", photoQuestionId);
    formData.append("application", appInfo.application.id);
    submitPhoto(formData);
    dispatch(setIsLoading(true));
    // insurance against race condition
    window.setTimeout(function () {
      dispatch(setIsLoading(false));
      if (!props.isLoop) {
        dispatch(
          incrementScreenProgress(
            progressCalculator(totalMilestones, totalScreensInMilestone)
          )
        );
      }
    }, 2000);
  }

  return (
    <form className={buttonClasses.photoButtonContainer}>
      <label className={buttonClasses.photoButton}>
        <input
          onChange={handlePhoto}
          ref={photoInput}
          type="file"
          capture="environment"
          accept="image/*"
        />
        {props.buttonText?.toUpperCase()}
      </label>
    </form>
  );
}

export default PhotoUploadButton;

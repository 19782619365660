import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearInputIdList } from "../actions";
import "../styles/SubmitApplication.css";

const ReferenceNumber = (caseNumber, pinNumber) => {
  return (
    <div className="reference-card">
      <div>
        <div className="label">Application ID / Case Number </div>
        <div className="number">{caseNumber}</div>
      </div>
      <div>
        <div className="label">PIN Number </div>
        <div className="number">{pinNumber}</div>
      </div>
    </div>
  );
};

const SubmitApplication = () => {
  const dispatch = useDispatch();
  const inputIdList = useSelector((state) => state.inputIdList);
  console.log(inputIdList);

  const appInfo = useSelector((state) => state.appInfo);

  // defensively clear out input ID list on this page, so that continue button is not disabled
  useEffect(() => {
    if (inputIdList.length) {
      dispatch(clearInputIdList());
    }
  });

  return (
    <div className="submit-application">
      <div className="text visit-text margin">
        Thank you for taking the time to complete this application. Are you
        ready to submit your application now?
        <br />
        <br />
        If you are not ready, please use the following information to return to
        this application in the future:
      </div>
      {ReferenceNumber(
        appInfo.application.application_id,
        appInfo.application.pin
      )}
    </div>
  );
};

export default SubmitApplication;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch } from "react-redux";
import classes from "../styles/Header.module.css";
import { toggleMenu } from "../actions";

function GenericHeader(props) {
  const dispatch = useDispatch();

  function closeMenu() {
    dispatch(toggleMenu());
  }

  function clickBackButton() {
    props.setStage("startup");
  }

  return (
    <Box className={classes.menuHeader}>
      {props.isResume ? (
        <IconButton
          className={classes.resumeBack}
          onClick={clickBackButton}
          color="primary"
          aria-label="back button"
        >
          <ArrowBackIcon />
          &nbsp;<span className={classes.backText}>Back</span>
        </IconButton>
      ) : (
        <div></div>
      )}
      <div className={classes.title}>{props.title}</div>
      {props.isMenu ? (
        <IconButton className={classes.icon} onClick={closeMenu}>
          <CancelOutlinedIcon color="primary" />
        </IconButton>
      ) : (
        <div></div>
      )}
    </Box>
  );
}

export default GenericHeader;

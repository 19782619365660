import { useState } from "react";
import { useSelector } from "react-redux";
import Error from "./components/Error";
import Loading from "../src/components/Loading";
import Prologue from "./components/Prologue";
import ResumeExisting from "./components/ResumeExisting";
import ScreenerApplication from "./components/ScreenerApplication";
import Startup from "./components/Startup";
import Thanks from "./components/Thanks";
import "./App.css";

function App() {
  /**
   * To any future devs who may work on this, I apologize for the spaghetti-esque quality of some of the code.
   * The ad hoc nature of certain parts of this app were determined by time constraints and poor initial API design decisions.
   * Also, this is the first React app I've ever built, and I was learning on the go.
   * I've tried to include as many comments as I can, to make the purpose of some of these code blocks more clear,
   * as well as TODOs, indicating where future feature work is required.
   */

  const [currentStage, setStage] = useState("startup");

  const isLoading = useSelector((state) => state.isLoading);
  const isError = useSelector((state) => state.error.isError);

  /**
   * I've divided up the main sections of the app into units called 'stages'. Moving between stages involves
   * passing the updateStage function below down to child components, which call the function at appropriate
   * times and bubble it back up here to the main App component.
   * This is not an ideal solution, and was chosen due to time constraints -- we had a
   * demo upcoming and I needed a fully working app.
   *
   * TODO: replace this structure with a router.
   * Right now there is zero support for native back navigation in this application. If you run it in the browser
   * and hit the BACK button, you won't be taken to the previous, you'll just leave the app entirely.
   * Obviously this is bad from a UX POV. I know it's bad, and I'm sorry.
   * I raised this concern about naitve back a few days ago in standup, but because of an upcoming deadline it was brushed aside.
   * I began working on integrating react router in the 'routing' branch, but again, time
   * constraints prevented me from completing this. The code is there if you want to build on it.
   *
   * General outline of the flow of the application:
   * 1) At start we hit the startup screen, where we have the option to either
   *    a) start a new application --> see 2) below
   *    b) resume an existing application --> see 3) below
   * 2) If we start a new application, we move to the 'prologue' stage, which walks us through
   *    the process of creating an application and then leads us to the main section of the app,
   *    what I've called the Screener Application: see 4) below
   * 3) Resume Exisitng: at the 'resume_existing' stage, a user can restart an application
   *    with the ID and PIN provided when they enter an email and phone # during 'prologue', these numbers are
   *    also accessible in the App Info tab of the header menu. Resuming an existing app takes them to the most recent
   *    screen where they have not provided any answers. "Question" screens are displayed in the
   *    "screener_application" stage, see 4) below
   * 4) Screener Application: the main body of this app, ~90% of it lives here. For more details on what's going
   *    on in this stage, see the ScreenerApplication component.
   * 5) Thank You: the thank you screen displayed when the user submits a completed application.
   *
   *  One very important TODO:
   *  - At the beginning of the application, there is an option for language selection -- English, Spanish, Other.
   *    Selecting a language does nothing, currently to my knowledge there is no logic on the backend
   *    related to changing languages.
   *    For language selection to work, **ALL** hard-coded text in this application
   *    will need to be removed and replaced with text provided by the API.
   */

  const stages = {
    startup: <Startup setStage={updateStage} />,
    prologue: <Prologue setStage={updateStage} />,
    resume_existing: <ResumeExisting setStage={updateStage} />,
    screener_application: <ScreenerApplication setStage={updateStage} />,
    thanks: <Thanks setStage={updateStage} />,
  };

  function updateStage(stageToSet) {
    setStage((current) => stageToSet);
  }

  return (
    <div className="App">
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error setStage={setStage} />
      ) : (
        stages[currentStage]
      )}
    </div>
  );
}

export default App;

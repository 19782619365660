import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const env = process.env.REACT_APP_ENVIRONMENT;
let baseURL;

switch (env) {
  case "dev":
    baseURL = "https://cima-devv2.analogyplus.com";
    break;

  case "test":
    baseURL = "https://cima-test.analogyplus.com";
    break;

  default:
    baseURL = "https://cima-betav2.analogyplus.com";
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().appInfo.token;
      if (token) {
        let accessToken, authScheme;
        if (typeof token === "string") {
          accessToken = token;
          authScheme = "Token";
        } else {
          accessToken = token.access;
          authScheme = "JWT";
        }
        headers.set("authorization", `${authScheme} ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});

import textClasses from "../styles/TextInputGroup.module.css";
import buttonClasses from "../styles/Button.module.css";
import { useDispatch } from "react-redux";
import { incrementMilestoneProgress } from "../actions";

function PhotoUpload(props) {
  const dispatch = useDispatch();

  function skipPhotos() {
    dispatch(incrementMilestoneProgress());
  }

  return (
    <div className={textClasses.group}>
      <p>{props.group_title}</p>
      <button onClick={skipPhotos} className={buttonClasses.linkLike}>
        Skip Adding Photos
      </button>
    </div>
  );
}

export default PhotoUpload;

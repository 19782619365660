export function progressCalculator(totalMilestones, numberOfScreens) {
    // calculate the amount of progress based on number of screens in step
    // and number of steps in application.
    // Example: if total number of steps is 5,
    // each step represents 20% of the application completed (100 / 5 = 20)
    // If number of screens in this step is 4,
    // progress forward or back within this step will change the progress bar by 5%
    // because 20 / 4 = 5.
    const stepsProgress = 100 / totalMilestones;
    const result = stepsProgress / numberOfScreens;
    return result
}

export function isValidEmail(input) {
    //eslint-disable-next-line
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return pattern.test(input.toLowerCase());
}

export function isValidPhoneNumber(input) {
    const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return pattern.test(input)
}
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, LinearProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "../styles/Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementMilestoneProgess,
  decrementScreenProgess,
  toggleMenu,
  setBackFlag,
} from "../actions";
import { progressCalculator } from "../util/utilFunctions";
import Menu from "./Menu";

function Header(props) {
  const dispatch = useDispatch();

  const progress = useSelector((state) => state.progress);
  const showMenu = useSelector((state) => state.showMenu);

  function clickBackButton() {
    dispatch(setBackFlag(true));
    if (props.stage === "prologue") {
      props.handleBack();
    } else {
      if (progress.currentScreen === 1) {
        console.log("moving back a whole milestone");
        // decrement progress bar
        let screenToLandOn =
          props.milestones[progress.currentMilestoneIndex - 1].screen_count;
        dispatch(decrementMilestoneProgess(screenToLandOn));
      } else {
        console.log("moving back a screen");
        dispatch(
          decrementScreenProgess(
            progressCalculator(
              progress.totalMilestones,
              progress.totalScreensInMilestone
            )
          )
        );
      }
    }
  }

  function menuToggle() {
    dispatch(toggleMenu());
  }

  return (
    <div>
      {showMenu ? (
        <Menu stage={props.stage} />
      ) : (
        <Box className={classes.header}>
          <Box className={classes.headerTop}>
            <IconButton
              disabled={
                progress.currentMilestoneIndex === 0 &&
                progress.currentScreen === 1
              }
              onClick={clickBackButton}
              color="primary"
              aria-label="back button"
            >
              <ArrowBackIcon />
              &nbsp;
              <span className="backText">Back</span>
            </IconButton>
            <div className="header_name">{props.milestoneTitle}</div>
            <IconButton onClick={menuToggle}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              value={progress.totalScreenProgress}
            />
          </Box>
        </Box>
      )}
    </div>
  );
}

export default Header;

import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleLogIn } from "../actions";
import "../styles/ApplicationReference.css";

const ReferenceNumber = (caseNumber, pinNumber) => {
  return (
    <div className="reference-card">
      <div>
        <div className="label">Application ID / Case Number </div>
        <div className="number">{caseNumber}</div>
      </div>
      <div>
        <div className="label">PIN Number </div>
        <div className="number">{pinNumber}</div>
      </div>
    </div>
  );
};

const ApplicationReference = (props) => {
  const [allow1, setAllow1] = useState(true);
  const [allow2, setAllow2] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLogIn());
  }, [dispatch]);

  const [inputErrors, setInputErrors] = useState({
    email: false,
    phone: false,
  });

  useEffect(() => {
    props.setDisableButton(allow1 || allow2);
  }, [allow1, allow2, props]);

  const handleInputChange = (key, value) => {
    if (key === "email") {
      const mock = !isValidEmail(value);
      setAllow1(mock);
      setInputErrors({
        ...inputErrors,
        email: mock,
      });
    } else if (key === "phone") {
      const mock = !isValidPhone(value);
      setAllow2(mock);
      setInputErrors({
        ...inputErrors,
        phone: mock,
      });
    }
    props.updateContactInfo(key, value);
  };

  const isValidEmail = (email) => {
    if (!email.length) return false;
    // Adapted from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    if (!phone.length) return false;
    // Adapted from https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const Input = (label, key) => {
    const type = key === "phone" ? "tel" : "email";

    return (
      <div>
        <div className="label">{label}</div>
        <TextField
          size="small"
          type={type}
          className="input-field"
          onChange={(event) => handleInputChange(key, event.target.value)}
          error={inputErrors[key]}
          helperText={inputErrors[key] ? `Please enter a valid ${key}.` : ""}
        />
      </div>
    );
  };

  return (
    <div className="application-reference">
      <div className="header">Application ID Number / Login</div>
      {ReferenceNumber(
        props.appInfo.application.application_id,
        props.appInfo.application.pin
      )}
      <div className="text">
        In order to securely track your application and, potentially, track the
        services you may be receiving, we begin by issuing you a Case Tracking
        Number (CTN) and Personal Identification Number (PIN).
        <br />
        <br />
        Please write these down and keep these in a safe place. You will need
        these to log in. This information will also be emailed and/or texted to
        you should you provide this information in the below boxes.
      </div>
      <div className="inputs">
        {Input("Email Address", "email")}
        {Input("Phone Number", "phone")}
      </div>
    </div>
  );
};

export default ApplicationReference;

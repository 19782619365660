import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllMilestonesQuery } from "../api/milestonesSlice";
import { useGetQuestionQuery } from "../api/questionSlice";
import {
  setScreensInMilestoneCount,
  setTotalMilestoneCount,
  setCurrentMilestoneIndex,
  setCurrentScreen,
  setInputIdList,
  setIsError,
  setMilestonesReference,
  setErrorMessage,
} from "../actions/index";
import Header from "./Header";
import Loading from "./Loading";
import Screen from "./Screen";

function ScreenerApplication(props) {
  const dispatch = useDispatch();

  const progress = useSelector((state) => state.progress);
  const appInfo = useSelector((state) => state.appInfo);

  /**
   * The client application is made up of milestones:
   *  - Survey Questions
   *  - Address and Contact Info
   *  - Household Info
   *  - Damage Assessment
   *  - Authorize and Submit
   * Each one of these milestones (which ended up being called 'steps' in the API,
   * my original name of 'milestones' is preserved here) is comprised of screens.
   * When the user hits the screener_application stage, we fetch the full list
   * of milestones, and move through them one at a time.
   * Progress through the application is tracked in state with the 'progress' object.
   * Each milestone is comprised of a list of screens.
   * We request all the screens at the beginning of a milestone.
   * When the user clicks 'continue' on the last screen in the current milestone,
   * (this is also tracked in 'progress') we tick up the currentMilestone counter.
   * All screens in the milestone are re-requested at each page refresh, so a tick up of the currentMilestone
   * automatically triggers a fetch of that milestone's screens.
   */

  const [milestoneSkipFlag, setMilestoneSkipFlag] = useState(false);

  const {
    data: milestones,
    isLoading: isMilestonesLoading,
    isFetching: isMilestonesFetching,
    isSuccess: isMilestonesSuccess,
    // isError: isMilestonesError,
    // error: milestonesError
  } = useGetAllMilestonesQuery("", { skip: milestoneSkipFlag });

  useEffect(() => {
    if (isMilestonesSuccess) {
      console.log("got milestones", milestones);
      setMilestoneSkipFlag((current) => true);
      dispatch(setMilestonesReference(milestones));
      dispatch(setTotalMilestoneCount(milestones.length));
      if (
        Object.hasOwn(appInfo.application, "resume_step_parameter") &&
        typeof appInfo.application.resume_step_parameter === "string"
      ) {
        let milestoneIndex = milestones.findIndex(
          (m) => m.step_parameter === appInfo.application.resume_step_parameter
        );
        dispatch(setCurrentMilestoneIndex(milestoneIndex));
        dispatch(setCurrentScreen(appInfo.application.resume_step_screen));
      }
    }
  }, [isMilestonesSuccess, appInfo, milestones, dispatch]);

  const {
    data: questions,
    isLoading: isQuestionsLoading,
    isFetching: isQuestionsFetching,
    isSuccess: isQuestionsSuccess,
    isError: isQuestionsError,
    error: questionsError,
  } = useGetQuestionQuery(
    {
      appId: appInfo.application.id,
      milestones: milestones,
      progress: progress,
    },
    { skip: isMilestonesLoading, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isQuestionsLoading) {
      console.log("Loading questions...");
      console.log("Milestones:", milestones);
    }
  }, [isQuestionsLoading, milestones]);

  useEffect(() => {
    if (isQuestionsError) {
      console.error("Error fetching questions:", questionsError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(questionsError));
    }
  }, [isQuestionsError, questionsError, dispatch]);

  useEffect(() => {
    if (isQuestionsSuccess) {
      console.log("got screens:", questions);
      /* setInputIdList is for used for validation.
             We inspect all inputs to be displayed on the screen, 
             and add the ids of all inputs marked as 'required' to an inputIdList, located in redux store.
             When the user interacts with one of these inputs, we remove that input from the inputIdList.
             The continue button is disabled by default, and only enabled when inputIdList is empty.
            */
      dispatch(setInputIdList(questions[0].question_groups));
      dispatch(
        setScreensInMilestoneCount(
          milestones[progress.currentMilestoneIndex].screen_count
        )
      );
    }
  });

  return (
    <div>
      {isQuestionsFetching ||
      isQuestionsLoading ||
      isQuestionsError ||
      isMilestonesLoading ||
      isMilestonesFetching ? (
        <Loading />
      ) : (
        <div>
          <Header
            setStage={props.setStage}
            numberOfScreens={
              milestones[progress.currentMilestoneIndex].screen_count
            }
            milestones={milestones}
            milestoneTitle={questions[0].question_step_title}
          />
          <Screen
            setStage={props.setStage}
            milestone={questions[0].question_step_title}
            screen={questions[0]}
          />
        </div>
      )}
    </div>
  );
}

export default ScreenerApplication;

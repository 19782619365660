import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setApplicationInfo, setErrorMessage, setIsError } from "../actions";
import { Button } from "@mui/material";
import buttonClasses from "../styles/Button.module.css";
import Introduction from "./Introduction";
import ApplicationAgreement from "./ApplicationAgreement";
import ApplicationReference from "./ApplicationReference";
import Header from "./Header";
import Loading from "./Loading";
import {
  useUpdateApplicationMutation,
  useCreateApplicationQuery,
} from "../api/applicationInfoSlice";
import "../styles/Prologue.module.css";
// import Language from './Language';
// import MenuPointer from './MenuPointer';

function Prologue(props) {
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();

  const [
    updateApplication,
    { isError: isUpdateError, error: updateError, isSuccess: updateSuccess },
  ] = useUpdateApplicationMutation();

  const [currentStep, setCurrentStep] = useState(0);

  const [allowContinue, setAllowContinue] = useState(false);

  const [contactInfo, setContactInfo] = useState({
    email: "",
    phone: "",
    application: "",
  });

  function updateAllowContinue(choice) {
    setAllowContinue((current) => choice);
  }

  function updateContactInfo(key, value) {
    setContactInfo((current) => {
      return { ...current, [key]: value };
    });
  }

  const {
    data: appInfo,
    isLoading,
    isSuccess,
    isError: isCreateError,
    error: createError,
  } = useCreateApplicationQuery("", { skip: currentStep !== 2 });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setApplicationInfo(appInfo));
      updateContactInfo("application", appInfo.application.id);
    }
  }, [isSuccess, isLoading, appInfo, dispatch]);

  useEffect(() => {
    if (isUpdateError) {
      console.error("Error updating application:", updateError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(updateError));
    }
  }, [dispatch, isUpdateError, updateError, contactInfo, props]);

  useEffect(() => {
    if (isCreateError) {
      console.error("Error creating application:", createError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(createError));
    }
  }, [isCreateError, createError, dispatch]);

  const steps = [
    { name: "introduction", component: <Introduction /> },
    {
      name: "application_agreement",
      component: (
        <ApplicationAgreement updateAllowContinue={updateAllowContinue} />
      ),
    },
    {
      name: "application_reference",
      component: (
        <ApplicationReference
          appInfo={appInfo}
          setDisableButton={setDisableButton}
          updateContactInfo={updateContactInfo}
          updateAllowContinue={updateAllowContinue}
        />
      ),
    },
    // {name: 'menu_pointer', component : <MenuPointer />},
    // {name: 'language', component : <Language />}
  ];

  if (updateSuccess) {
    props.setStage("screener_application");
  }

  function handleBack() {
    // ignoring back button on these screens for now...
  }

  function handleContinue() {
    if (currentStep >= steps.length - 1) {
      updateApplication(contactInfo);
    } else {
      setCurrentStep((current) => current + 1);
    }
  }

  return (
    <div className="prologue">
      <Header
        stage="prologue"
        milestoneTitle="Introduction"
        handleBack={handleBack}
      />
      {isLoading ? <Loading /> : steps[currentStep].component}
      <div className={buttonClasses.buttonContainer}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={buttonClasses.button}
          onClick={handleContinue}
          disabled={disableButton || (currentStep > 0 && !allowContinue)}
        >
          {steps[currentStep].name === "menu_pointer" ? "OK" : "Continue"}
        </Button>
      </div>
    </div>
  );
}

export default Prologue;

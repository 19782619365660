import { useSelector } from 'react-redux';
import classes from '../styles/ReferenceNumber.module.css';

function ReferenceNumber () {

    const appInfo = useSelector(state => state.appInfo);

    return (
      <div className={classes.referenceCard}>
        <div>
          <div className={classes.label}>Application ID / Case Number </div>
          <div className={classes.number}>{appInfo.application.application_id}</div>
        </div>
        <div>
          <div className={classes.label}>PIN Number </div>
          <div className={classes.number}>{appInfo.application.pin}</div>
        </div>
      </div>
    );
  };

  export default ReferenceNumber;
import { Rating } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeInputIdFromList, updateAnswers } from "../actions";
import "../styles/Rating.css";
import TextInputGroup from "./TextInputGroup";

const RatingPage = (props) => {

  const [value, setValue] = useState(0);

  const appId = useSelector(state => state.appInfo.application.id);

  const dispatch = useDispatch();

  return (
    <div className="Rating">
      {/* <div className="header margin">Rate your experience</div> */}
      <div className="visit-text bold margin">
        How would you rate your experience completing this application?
      </div>
      <div className="reference-card margin">
        <span>
          <Rating
            value={value}
            className="star-rating"
            onChange={(event, newValue) => {
              setValue(newValue);
              dispatch(updateAnswers(
                { application: appId,
                question: props.question_groups[0].questions[0].id.toString(),
                value: newValue }
              ))
              dispatch(removeInputIdFromList(props.question_groups[0].questions[0].id.toString()));
            }}
          />
        </span>
        <span className="rating-text">
          <span>Poor</span>
          <span>OK</span>
          <span>Great</span>
        </span>
      </div>
      <TextInputGroup 
          groupIndex={props.question_groups[1].id.toString()}
          group_title={props.question_groups[1].group_title}
          group_desc={props.question_groups[1].group_desc}
          questions={props.question_groups[1].questions}
          groupType={props.question_groups[1].group_type}
      />
    </div>
  );
};

export default RatingPage;

import { apiSlice } from "./apiSlice";

export const refreshSlice = apiSlice.injectEndpoints({
    reducerPath: 'refresh',
    endpoints: builder => ({
        refreshAccessToken: builder.mutation({
            query: (refreshToken) => ({
                url: '/token/refresh/',
                method: 'POST',
                body: {'refresh': refreshToken}
            })
        })
    })
})

export const { useRefreshAccessTokenMutation } = refreshSlice;
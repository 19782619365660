import { combineReducers } from "redux";
import answersReducer from "./currentStepAnswers";
import progressReducer from "./progress";
import { milestonesApiSlice } from "../api/milestonesSlice";
import { questionApiSlice } from "../api/questionSlice";
import { enteredItemsSlice } from "../api/enteredItemsSlice";
import { applicationInfoSlice } from "../api/applicationInfoSlice";
import applicationReducer from "./application";
import inputIdListReducer from "./inputIdList";
import milestonesReferenceReducer from "./milestones";
import errorReducer from "./error";

const allReducers = combineReducers({
  answers: answersReducer,
  appInfo: applicationReducer,
  error: errorReducer,
  inputIdList: inputIdListReducer,
  milestonesReference: milestonesReferenceReducer,
  progress: progressReducer,
  [questionApiSlice.reducerPath]: questionApiSlice.reducer,
  [enteredItemsSlice.reducerPath]: enteredItemsSlice.reducer,
  [applicationInfoSlice.reducerPath]: applicationInfoSlice.reducer,
  [milestonesApiSlice.reducerPath]: milestonesApiSlice.reducer,
  language: (state = "English") => {
    return state;
  },
  isLoggedIn: (state = false, action) => {
    switch (action.type) {
      case "TOGGLE_LOG_IN":
        return !state;
      default:
        return state;
    }
  },
  isAutoLogOut: (state = false, action) => {
    switch (action.type) {
      case "SET_IS_AUTO_LOGOUT":
        return action.payload;
      default:
        return state;
    }
  },
  isBackNavigation: (state = false, action) => {
    switch (action.type) {
      case "SET_BACK_FLAG":
        return action.payload;
      default:
        return state;
    }
  },
  showMenu: (state = false, action) => {
    switch (action.type) {
      case "TOGGLE_MENU":
        return !state;
      default:
        return state;
    }
  },
  isRejected: (state = false, action) => {
    switch (action.type) {
      case "FLAG_REJECTED":
        return action.payload;
      default:
        return state;
    }
  },
  isLoading: (state = false, action) => {
    switch (action.type) {
      case "SET_IS_LOADING":
        return action.payload;
      default:
        return state;
    }
  },
  photoQuestionId: (state = null, action) => {
    switch (action.type) {
      case "SET_PHOTO_QUESTION_ID":
        return action.payload;
      default:
        return state;
    }
  },
});

export default allReducers;

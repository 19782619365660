import "../styles/Introduction.css";
import Tick from "../assets/images/tick.svg";

const list = (text, ind) => {
  return (
    <div className="list text" id={ind} key={ind}>
      <span>
        <img src={Tick} alt="tick" />
      </span>
      <span>{text}</span>
    </div>
  );
};

const listContent = [
  "Low-to-moderate income and medically fragile households that have been displaced due to minor or major storm damage of their home,",
  "Owner-occupied house (that is, not a rental/rented property), and",
  "Residents of Norfolk and Portsmouth. ",
];

const Introduction = () => {
  return (
    <div className="introduction">
      <div className="header">Introduction</div>
      <div className="text">
        Recover Hampton Roads provides services to address minor and major storm
        damage to a home that has made the house uninhabitable and has resulted
        in displacement of the household. Homes that have been destroyed,
        meaning the structure is a total loss and repair is not feasible, are
        not eligible for our services.
      </div>

      <div className="sub-header">Who we can help</div>
      <div className="text margin">
        We partner with the displaced homeowner to create a work plan to repair
        storm-induced damage to their home and assist in the coordination of
        donated materials and volunteer labor.
        <br />
        <br />
        Recover Hampton Roads services are available to:
        {listContent.map((listItem, ind) => {
          return list(listItem, ind);
        })}
      </div>
    </div>
  );
};

export default Introduction;

import ShortcutIcon from '@mui/icons-material/Shortcut';
import menuPointerClasses from '../styles/MenuPointer.module.css';

function MenuPointer() {
    return ( 
        <div className={menuPointerClasses.main}>
            <div className={menuPointerClasses.arrow}>
                <ShortcutIcon />
            </div>
            <div className={menuPointerClasses.text}>Click on the menu button anytime to get information about you application and to access help and support</div>
        </div>
     );
}

export default MenuPointer;
export const incrementScreenProgress = (payload) => {
  return {
    type: "INCREMENT_SCREEN_PROGRESS",
    payload: payload,
  };
};

export const decrementScreenProgess = (payload) => {
  return {
    type: "DECREMENT_SCREEN_PROGRESS",
    payload: payload,
  };
};

export const incrementMilestoneProgress = () => {
  return {
    type: "INCREMENT_MILESTONE_PROGRESS",
  };
};

export const decrementMilestoneProgess = (screenToLandOn) => {
  return {
    type: "DECREMENT_MILESTONE_PROGRESS",
    payload: screenToLandOn,
  };
};

export const setTotalMilestoneCount = (milestonesLength) => {
  return {
    type: "SET_TOTAL_MILESTONE_COUNT",
    payload: milestonesLength,
  };
};

export const setTotalScreensCount = (milestones) => {
  return {
    type: "SET_TOTAL_SCREENS_COUNT",
    payload: milestones,
  };
};

export const setScreensInMilestoneCount = (numberOfScreens) => {
  return {
    type: "SET_SCREENS_IN_MILESTONE_COUNT",
    payload: numberOfScreens,
  };
};

export const getAllMilestones = () => {
  return {
    type: "GET_ALL_MILESTONES",
  };
};

export const updateAnswers = (payload) => {
  return {
    type: "UPDATE_ANSWERS",
    payload: payload,
  };
};

export const clearAnswers = () => {
  return {
    type: "CLEAR_ANSWERS",
  };
};

export const setApplicationInfo = (payload) => {
  return {
    type: "SET_APPLICATION_INFO",
    payload: payload,
  };
};

export const flagRejected = (payload) => {
  return {
    type: "FLAG_REJECTED",
    payload: payload,
  };
};

export const toggleLogIn = () => {
  return {
    type: "TOGGLE_LOG_IN",
  };
};

export const setIsAutoLogOut = (payload) => {
  return {
    type: "SET_IS_AUTO_LOGOUT",
    payload: payload,
  };
};

export const toggleMenu = () => {
  return {
    type: "TOGGLE_MENU",
  };
};

export const setIsLoading = (payload) => {
  return {
    type: "SET_IS_LOADING",
    payload: payload,
  };
};

export const setIsError = (payload) => {
  return {
    type: "SET_IS_ERROR",
    payload: payload,
  };
};

export const setErrorMessage = (payload) => {
  return {
    type: "SET_ERROR_MESSAGE",
    payload: payload,
  };
};

export const setCurrentMilestoneIndex = (payload) => {
  return {
    type: "SET_CURRENT_MILESTONE_INDEX",
    payload: payload,
  };
};

export const setCurrentScreen = (payload) => {
  return {
    type: "SET_CURRENT_SCREEN",
    payload: payload,
  };
};

export const setInputIdList = (question_groups) => {
  let idList = question_groups
    .map((group) =>
      group.questions.map((q) => (q.required ? q.id.toString() : null))
    )
    .flat()
    .filter((q) => q !== null);
  return {
    type: "SET_INPUT_ID_LIST",
    payload: idList,
  };
};

export const removeInputIdFromList = (id) => {
  return {
    type: "REMOVE_INPUT_ID_FROM_LIST",
    payload: id,
  };
};

export const clearInputIdList = () => {
  return {
    type: "CLEAR_INPUT_ID_LIST",
  };
};

export const resetProgress = () => {
  return {
    type: "RESET_PROGRESS",
  };
};

export const clearApplicationInfo = () => {
  return {
    type: "CLEAR_APPLICATION_INFO",
  };
};

export const setAccessToken = (payload) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: payload,
  };
};

export const setMilestonesReference = (payload) => {
  return {
    type: "SET_MILESTONES_REFERENCE",
    payload: payload,
  };
};

export const clearMilestonesReference = () => {
  return {
    type: "CLEAR_MILESTONES_REFERENCE",
  };
};

export const setPhotoQuestionID = (payload) => {
  return {
    type: "SET_PHOTO_QUESTION_ID",
    payload: payload,
  };
};

export const setBackFlag = (payload) => {
  return {
    type: "SET_BACK_FLAG",
    payload: payload,
  };
};

import React, { useEffect, useState } from "react";
import { Button, Snackbar } from "@mui/material";
import buttonClasses from "../styles/Button.module.css";
import startupClasses from "../styles/Startup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setIsAutoLogOut } from "../actions";

function Startup(props) {
  const dispatch = useDispatch();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const isAutoLogOut = useSelector((state) => state.isAutoLogOut);

  useEffect(() => {
    if (isAutoLogOut) {
      setIsSnackbarOpen(true);
      dispatch(setIsAutoLogOut(false));
    }
  }, [isAutoLogOut, setIsSnackbarOpen, dispatch]);

  function handleClick(stage) {
    props.setStage(stage);
  }

  return (
    <div>
      <div className={startupClasses.loginHeader}>
        <div className={startupClasses.circles}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>CIMA</h1>
      </div>
      <div className={startupClasses.header}>
        Application for Home Disaster Relief & Support
      </div>
      <div
        className={`${buttonClasses.buttonContainer} ${buttonClasses.startupContainer}`}
      >
        <Button
          variant="contained"
          size="large"
          className={buttonClasses.button}
          color="primary"
          onClick={function () {
            handleClick("prologue");
          }}
        >
          Start New Application
        </Button>
        <p>or</p>
        <button
          onClick={function () {
            handleClick("resume_existing");
          }}
          className={buttonClasses.linkLike}
        >
          Continue Existing Application
        </button>
      </div>
      <Snackbar
        autoHideDuration={10000}
        open={isSnackbarOpen}
        onClose={function () {
          setIsSnackbarOpen(false);
        }}
        message="You have been logged out due to inactivity. Please use the 'Continue Existing Application' button to log back in."
      />
    </div>
  );
}

export default Startup;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { progressCalculator } from "../util/utilFunctions";
import {
  clearAnswers,
  incrementMilestoneProgress,
  incrementScreenProgress,
  setPhotoQuestionID,
  setBackFlag,
  setIsError,
  setErrorMessage,
  flagRejected,
} from "../actions";
import { useSubmitAnswersMutation } from "../api/answerSubmitSlice";
import { Button } from "@mui/material";
import ApplicationStatus from "./ApplicationStatus";
import AuthorizeVisit from "./AuthorizeVisit";
import CheckboxGroup from "./CheckboxGroup";
import Help from "./Help";
import RadioButtonGroup from "./RadioButtonGroup";
import PhotoUpload from "./PhotoUpload";
import PhotoUploadButton from "./PhotoUploadButton";
import PreviouslyEnteredItems from "./PreviouslyEnteredItems";
import Rating from "./Rating";
import RejectionMessage from "./RejectionMessage";
import SubmitApplication from "./SubmitApplication";
import TextInputGroup from "./TextInputGroup";
import screenClasses from "../styles/Screen.module.css";
import buttonClasses from "../styles/Button.module.css";
import miscClasses from "../styles/Misc.module.css";
import MenuPointer from "./MenuPointer";
import PlainText from "./PlainText";
import { useSubmitApplicationMutation } from "../api/submitApplicationSlice";

// Most of the action in this application occurs in this component.
function Screen(props) {
  const dispatch = useDispatch();

  const answers = useSelector((state) => state.answers);
  const currentMilestoneIndex = useSelector(
    (state) => state.progress.currentMilestoneIndex
  );
  const currentScreen = useSelector((state) => state.progress.currentScreen);
  const inputIdList = useSelector((state) => state.inputIdList);
  const isBackNavigation = useSelector((state) => state.isBackNavigation);
  const isRejected = useSelector((state) => state.isRejected);
  const totalMilestones = useSelector(
    (state) => state.progress.totalMilestones
  );
  const totalScreensInMilestone = useSelector(
    (state) => state.progress.totalScreensInMilestone
  );
  const appInfo = useSelector((state) => state.appInfo);

  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isPhotoUpload, setIsPhotoUpload] = useState(false);
  const [showRejection, toggleRejectionMessage] = useState(false);

  const [submitAnswers, { isError: isSubmitError, error: submitError }] =
    useSubmitAnswersMutation();

  const [submitApplication] = useSubmitApplicationMutation();

  useEffect(() => {
    // ensure window resets to top on mobile devices
    window.scrollTo(0, 0);

    // sanity check to ensure that previous answers are properly populated when navigating back to previous screen
    if (isBackNavigation) {
      dispatch(clearAnswers());
    }

    // storing off the id of the 'photo upload' question, so uploaded photos
    // can be retrieved on next screen
    setIsPhotoUpload(evalIsPhotoUpload());
    if (isPhotoUpload) {
      dispatch(
        setPhotoQuestionID(props.screen.question_groups[0].questions[0].id)
      );
    }

    // eval if this is the 'upload photos' screen,
    // and differentiate between the upload ('fixed' screen_type) & review ('loop' screen_type)
    function evalIsPhotoUpload() {
      if (
        typeof props.screen.screen_title === "string" &&
        props.screen.screen_type === "fixed"
      ) {
        return props.screen.screen_title?.toUpperCase().includes("PHOTOS");
      }
      return false;
    }
  }, [props, dispatch, isBackNavigation, isRejected, isPhotoUpload]);

  useEffect(() => {
    if (isSubmitError) {
      console.error("Error submitting answers:", submitError);
      dispatch(setIsError(true));
      dispatch(setErrorMessage(submitError));
    }
  }, [isSubmitError, submitError, dispatch]);

  useEffect(() => {
    // handling an edge-case bug where the user selects a "rejectable" answer, then hits
    // back button, rather than continue
    if (isBackNavigation) {
      dispatch(flagRejected(false));
    }
  }, [isBackNavigation, dispatch]);

  // determines whether answers for this question have been previously submitted,
  // which is a factor in determining whether continue button should be disabled
  const areQuestionsAnswered = hasAnswers(props.screen);

  function hasAnswers(screen) {
    return screen.question_groups.reduce(function (acc, group) {
      return checkForAnswers(acc, group);
    }, false);

    function checkForAnswers(acc, group) {
      return group.questions.reduce(function (acc, question) {
        return question.answers.length > 0;
      }, false);
    }
  }

  // dynamically generates screens based on information from API
  function generateScreen(screen) {
    // due to time crunch, screens for the final "Authorize and Submit" step were hard coded,
    // future dev work would involve adding the text and inputs displayed here to the API
    if (screen.question_step_title === "Authorize and Submit") {
      if (screen.screen_title?.toUpperCase() === "AUTHORIZE VISIT") {
        return <AuthorizeVisit question_groups={screen.question_groups} />;
      } else if (
        screen.screen_title?.toUpperCase() === "SUBMIT YOUR APPLICATION"
      ) {
        return <SubmitApplication question_groups={screen.question_groups} />;
      } else if (screen.screen_title?.toUpperCase() === "RATE YOUR EXPERIENCE") {
        return <Rating question_groups={screen.question_groups} />;
      } else if (screen.screen_title?.toUpperCase() === "APPLICATION STATUS") {
        return <ApplicationStatus />;
      }
    } else {
      // 'fixed' vs 'loop' screens: loop screens are those like 'displaced household members'
      // and 'add photos of damage', where the user has an opportunity to enter data multiple times.
      // After entering the data, user is presented with a review screen where they can see and delete
      // previously entered data.
      // Inputs are grouped together into "question groups", each input (text field, radio button, etc),
      // is an individual 'question' object within an array that is a field in the 'question group' object
      // an individual input will be located within the 'screen' object like so:
      // screen.question_groups[x].questions[y]
      if (screen.screen_type === "fixed") {
        let screenGroups = screen.question_groups.map((questionGroup, idx) => {
          if (
            questionGroup.group_type === "text_field" ||
            questionGroup.group_type === "form" ||
            questionGroup.group_type === "text_area"
          ) {
            return (
              <TextInputGroup
                key={idx}
                groupType={questionGroup.group_type}
                groupIndex={idx}
                group_title={questionGroup.group_title}
                group_desc={questionGroup.group_desc}
                questions={questionGroup.questions}
              />
            );
          } else if (questionGroup.group_type === "radio_button") {
            return (
              <RadioButtonGroup
                key={idx}
                groupIndex={idx}
                group_title={questionGroup.group_title}
                questions={questionGroup.questions}
                isRejectable={
                  props.screen.question_step_title?.toUpperCase() ===
                  "SURVEY QUESTIONS"
                }
                toggleRejectionMessage={toggleRejectionMessage}
              />
            );
          } else if (
            questionGroup.group_type === "multiple_choice" ||
            questionGroup.group_type === "choice" ||
            questionGroup.group_type === "checkbox"
          ) {
            return (
              <CheckboxGroup
                key={idx}
                groupId={idx}
                group_title={questionGroup.group_title}
                group_desc={questionGroup.group_desc}
                questions={questionGroup.questions}
              />
            );
          } else if (questionGroup.group_type === "upload_photos") {
            return (
              <PhotoUpload
                key={idx}
                groupId={idx}
                group_title={questionGroup.group_title}
                group_desc={questionGroup.group_desc}
                questions={questionGroup.questions}
              />
            );
          } else if (questionGroup.group_type === "application_status") {
            return <ApplicationStatus key={idx} />;
          } else if (questionGroup.group_type === "menu_help") {
            return <MenuPointer key={idx} />;
          } else if (questionGroup.group_type === "plain_text") {
            return (
              <PlainText key={idx} group_title={questionGroup.group_title} />
            );
          } else {
            return <div></div>;
          }
        });
        return screenGroups;
      } else if (screen.screen_type === "loop") {
        return (
          <PreviouslyEnteredItems
            screen_group_name={screen.screen_group_name}
            screen_title={screen.screen_title}
            screen_api_details={screen.screen_api_details}
          />
        );
      } else {
        return <div className={miscClasses.hidden}></div>;
      }
    }
  }

  function toggleHelp() {
    setIsHelpOpen((current) => !current);
  }

  // CONTINUE button handling
  function handleButtonClick(skip) {
    if (props.screen.screen_title?.toUpperCase() === "SUBMIT YOUR APPLICATION") {
      submitApplication(appInfo.application.id);
    }
    // flag to indicate whether we are moving backwards or forwards
    dispatch(setBackFlag(false));
    // check to see if the answer is disqualifying before continuing
    if (isRejected) {
      toggleRejectionMessage((current) => true);
    } else {
      if (!skip) {
        submitAnswers(answers);
      }
      dispatch(clearAnswers());
      if (totalScreensInMilestone === currentScreen) {
        if (currentMilestoneIndex === totalMilestones - 1) {
          props.setStage("thanks");
        } else {
          dispatch(incrementMilestoneProgress());
        }
      } else {
        dispatch(
          incrementScreenProgress(
            progressCalculator(totalMilestones, totalScreensInMilestone)
          )
        );
      }
    }
  }

  function hideHelpButton() {
    return isPhotoUpload || isSpecialScreen();
  }

  // At the last minute, to resolve some bugs that QA was uncovering, I made a change on the backend
  // that should have been made long ago.
  // Certain screens (application status, the survey questions intro) which are present in the FIGMA file
  // were not originally included in the API. In order to display these screens,
  // the FE had to infer the need to display them from context.
  // Because this nonsensical arrangement created a lot of bugs, after much wrangling with my
  // backend developer I eventually just went into the django admin panel and added the screens myself.
  // Unfortunately the html/css structure here doesn't exactly match up with what those screens
  // are supposed to look like. Because of time crunch, I didn't want to rewrite this structure,
  // so I flagged them as 'special' and removed css properties based on that.
  function isSpecialScreen() {
    if (typeof props.screen.screen_parameter === "string") {
      return props.screen.screen_parameter?.toUpperCase().includes("SPECIAL");
    }
    return false;
  }

  function getButtonText() {
    if (typeof props.screen.screen_title === "string") {
      return props.screen.screen_title?.toUpperCase().includes("SUBMIT")
        ? "Submit Application"
        : "Continue";
    }
    return "Continue";
  }

  /*
   * THE BIG BANG -- where it all comes together
   */
  const generatedScreen = generateScreen(props.screen);

  return (
    <main className={screenClasses.main}>
      {
        <div className={screenClasses.mainBody}>
          <div
            className={
              isSpecialScreen() ? miscClasses.hidden : screenClasses.text
            }
          >
            <p className={screenClasses.questionText}>
              {props.screen.screen_title}
            </p>
          </div>

          <div className={isSpecialScreen() ? "" : screenClasses.questionsBox}>
            {generatedScreen}
          </div>

          {hideHelpButton() ? (
            <></>
          ) : (
            <Button
              sx={{ display: showRejection ? "none" : "auto" }}
              className={screenClasses.help}
              onClick={toggleHelp}
            >
              Need Help?
            </Button>
          )}
        </div>
      }

      <div className={buttonClasses.buttonContainer}>
        {props.screen.skippable ? (
          <button
            onClick={function () {
              handleButtonClick(true);
            }}
            className={buttonClasses.linkLike}
          >
            skip this question
          </button>
        ) : (
          <></>
        )}

        {/* When we come to uploading photos, substitute this 'fake' button for the usual continue button */}
        {isPhotoUpload ? (
          <PhotoUploadButton buttonText="Continue" buttonStyle="photoButton" />
        ) : (
          <Button
            className={buttonClasses.button}
            sx={{ display: showRejection ? "none" : "auto" }}
            size="large"
            color="primary"
            onClick={function () {
              handleButtonClick(false);
            }}
            disabled={inputIdList.length > 0 && !areQuestionsAnswered}
            variant="contained"
          >
            {getButtonText()}
          </Button>
        )}
      </div>

      {showRejection ? <RejectionMessage /> : <></>}
      {isHelpOpen ? <Help toggleHelp={toggleHelp} /> : <></>}
    </main>
  );
}

export default Screen;

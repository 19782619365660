import { apiSlice } from "./apiSlice";


export const questionApiSlice = apiSlice.injectEndpoints({
    reducerPath: 'question',
    endpoints: builder => ({
        getQuestion: builder.query({
            query: ({appId, milestones, progress}) => `/v1/applications/steps/screen/?screen_id=${progress.currentScreen}&application_id=${appId}&step_parameter=${milestones[progress.currentMilestoneIndex].step_parameter}`
        })
    })
})

export const {
    useGetQuestionQuery
} = questionApiSlice;
import { configureStore } from '@reduxjs/toolkit'
import allReducers from './reducers/index';
import { questionApiSlice } from './api/questionSlice'

export const setupStore = (preloadedState) => {
    return configureStore({
        reducer: allReducers,
        middleware: (getDefaultMiddleware) => 
            getDefaultMiddleware().concat(questionApiSlice.middleware),
        preloadedState
    })
}



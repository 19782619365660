import { Button } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { decrementScreenProgess, setIsError, setErrorMessage } from '../actions';
import { useGetEnteredItemsQuery, useDeleteItemMutation } from '../api/enteredItemsSlice';
import { useDeletePhotoMutation } from '../api/photoSlice';
import photoStyles  from '../styles/Photos.module.css';
import buttonStyles from '../styles/Button.module.css';
import miscStyles from '../styles/Misc.module.css';
import PhotoUploadButton from './PhotoUploadButton';
import { useEffect } from 'react';

function PreviouslyEnteredItems(props) {

    const dispatch = useDispatch();

    const appInfo = useSelector(state => state.appInfo);

    const [deleteItem, {isError: isDeleteItemError, error: deleteItemError}] = useDeleteItemMutation();
    const [deletePhoto, {isError: isDeletePhotoError, error: deletePhotoError}] = useDeletePhotoMutation();

    function removeElement(id) {
        let toDelete = document.getElementById(id);
        toDelete.remove();
        refetch();
    }

    function removeMember(id) {
        deleteItem(id);
        removeElement(id);
    }

    function removePhoto(id) {
        deletePhoto(id);
        removeElement(id);
    }

    function householdMemberItem(obj) {
        return  <div key={obj.id} id={obj.id.toString()} className={miscStyles.marginBottomSmall}>
                    <Button className={buttonStyles.displacedMember}>
                        {obj.first_name} {obj.last_name}, Age {obj.age}
                    </Button>
                    <HighlightOffOutlinedIcon className={buttonStyles.previouslyEnteredClose} color="warning" onClick={function(){removeMember(obj.id)}}/>
                </div>
    }

    function photoItem(obj) {
        return  <div key={obj.id} id={obj.id.toString()} className={photoStyles.photo} >
                    <img src={obj.file} alt="damage to property" />
                    <HighlightOffOutlinedIcon color="warning" className={photoStyles.photoDelete} onClick={function(){removePhoto(obj.id)}} />
                </div>
    }

    const {
        refetch,
        data: items,
        isSuccess,
        // isGetItemsError,
        // getItemsError
      } = useGetEnteredItemsQuery({appId: appInfo.application.id, group_name: props.screen_group_name}, { refetchOnMountOrArgChange: true })

      let previousItems = <div></div>;

      if(isSuccess) {
        console.log("got items", items);
        if(props.screen_group_name === 'displaced_household_members') { 
            previousItems = items.map(item => householdMemberItem(item))
        } else if(props.screen_group_name === 'damage_assessment_media' && items.length > 0) {
            previousItems = items[0].damageassessmentmedia_set.map(item => photoItem(item));
        } else {
            previousItems = <div></div>
        }
      }

    useEffect(() => {
        if(isDeleteItemError) {
            console.error("Error deleting previously entered item:", deleteItemError);
            dispatch(setIsError(true));
            dispatch(setErrorMessage(deleteItemError))
        }
    }, [isDeleteItemError, deleteItemError, dispatch])

    useEffect(() => {
        if(isDeletePhotoError) {
            console.error("Error deleting previously entered item:", deletePhotoError);
            dispatch(setIsError(true));
            dispatch(setErrorMessage(deletePhotoError))
        }
    }, [isDeletePhotoError, deletePhotoError, dispatch])
    
    function addMore() {
        dispatch(decrementScreenProgess());
    }


    return ( 
        <div>
            <h3>{props.screen_title}</h3>
            <p>{`You have added ${previousItems.length} item${previousItems.length !== 1 ? 's' : ''}:`}</p>

            <div className={`${miscStyles.marginBottomSmall} && ${props.screen_group_name === 'damage_assessment_media' ? photoStyles.photoContainer : ''}`}>
                {previousItems}
            </div>

            {
                props.screen_group_name === 'damage_assessment_media' ? 
                <PhotoUploadButton isLoop={true} buttonText="Add More" buttonStyle="photoButton" /> :
                <Button variant="contained" onClick={addMore}>Add More</Button>
            }

            <button 
                onClick={function(){refetch()}}
                className={buttonStyles.linkLike}>
                    Don't see the items you entered? Click here to refetch data
            </button>
        </div>
     );
}

export default PreviouslyEnteredItems;
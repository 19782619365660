import { apiSlice } from "./apiSlice";

export const answerSubmitSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitAnswers: builder.mutation({
            query: (answers) => ({
                url: '/v1/applications/answersv1/',
                method: 'POST',
                body: answers
            })
        })
    })
})

export const {
    useSubmitAnswersMutation
} = answerSubmitSlice;
import { apiSlice } from "./apiSlice";

export const applicationInfoSlice = apiSlice.injectEndpoints({
  reducerPath: "applicationInfo",
  endpoints: (builder) => ({
    createApplication: builder.query({
      query: () => "/v1/applications/createv1/",
    }),
    updateApplication: builder.mutation({
      query: (contactInfo) => ({
        url: "/v1/applications/update/",
        method: "POST",
        body: contactInfo,
      }),
    }),
    resumeApplication: builder.mutation({
      query: (idAndPin) => ({
        url: "/v1/applications/loginv1/",
        method: "POST",
        body: idAndPin,
      }),
    }),
  }),
});

export const {
  useCreateApplicationQuery,
  useUpdateApplicationMutation,
  useResumeApplicationMutation,
} = applicationInfoSlice;

import { produce } from 'immer';

const initialState = [];

const inputIdListReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_INPUT_ID_LIST":
            return action.payload;
        case "REMOVE_INPUT_ID_FROM_LIST":
            return produce(state, draft => {
                return draft.filter(id => id !== action.payload)
            })
        case "CLEAR_INPUT_ID_LIST":
            return [];
        default:
            return state;
    }
}

export default inputIdListReducer;
import { apiSlice } from "./apiSlice";

export const submitApplicationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitApplication: builder.mutation({
      query: (application_id) => ({
        url: "/v1/applications/status_change/",
        method: "POST",
        body: {
          application_id: application_id,
          status: "ready_for_review",
        },
      }),
    }),
  }),
});

export const { useSubmitApplicationMutation } = submitApplicationSlice;

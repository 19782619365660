import { Checkbox, Box, FormGroup, FormControlLabel } from "@mui/material";
import { useDispatch } from 'react-redux';
import { removeInputIdFromList } from "../actions";

function CheckboxGroup(props) {

    const questions = props.questions;

    const dispatch = useDispatch();

    const handleChange = (event) => {
        dispatch(removeInputIdFromList(props.questions[0].id.toString()))
    }

    return (
        <Box>
            <p>{questions[0].title}</p>
            {props.group_desc ? <p>{props.group_desc}</p> : null}
    
            <FormGroup>
                {
                    questions[0].choices.map(choice => {
                        return <FormControlLabel key={choice} control={<Checkbox onChange={handleChange} defaultChecked={false} id={choice} color="secondary" /> } label={choice} />
                    })
                }
            </FormGroup>
        </Box>
     );
}

export default CheckboxGroup;
import { apiSlice } from "./apiSlice";


export const milestonesApiSlice = apiSlice.injectEndpoints({
    reducerPath: 'milestones',
    endpoints: builder => ({
        getAllMilestones: builder.query({
            query: () => '/v1/applications/steps/?module_name=client_module'
        }),
    })
})

export const {
    useGetAllMilestonesQuery
} = milestonesApiSlice;
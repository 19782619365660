import { CircularProgress } from "@mui/material";
import classes from "../styles/Loading.module.css";

function Loading() {
  return (
    <div className={classes.loadingOuter}>
      <div className={classes.loadingInner}>
        <CircularProgress />
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default Loading;

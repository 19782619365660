import { IconButton } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import classes from '../styles/Help.module.css';

function Help(props) {

    /**
     * TODO:
     *  Text context for question-specific help screens needs to be supplied by the API.
     */

    function closeHelp() {
        props.toggleHelp();
    }

    return (
        <div className={classes.help}>
            <div className={classes.helpHeader}>
                <p>Additional Information</p>
                <IconButton className={classes.icon} onClick={closeHelp}>
                    <CancelOutlinedIcon style={{'color': '#005280'}} />
                </IconButton>
            </div>
            <div className={classes.helpBody}>
                <p>This is additional help information about the question.</p>
                <p>Include additional support phone number or other information here as well.</p>
            </div>
        </div> 
        
     );
}

export default Help;
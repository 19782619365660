import TextField from "@mui/material/TextField";
import classes from "../styles/TextInputGroup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { removeInputIdFromList, updateAnswers } from "../actions";

function TextInputGroup(props) {
  const dispatch = useDispatch();

  const questions = props.questions;

  const appId = useSelector((state) => state.appInfo.application.id);

  let answerObject = {
    application: appId,
    question: "",
    value: "",
  };

  const handleChange = (event) => {
    answerObject.question = event.target.id;
    answerObject.value = event.target.value;
    dispatch(updateAnswers(answerObject));
    dispatch(removeInputIdFromList(event.target.id));
  };

  function inferInputType(question_parameter) {
    switch (question_parameter) {
      case "income":
        return "number";
      default:
        return "text";
    }
  }

  function getDecoratorClass(question_parameter) {
    switch (question_parameter) {
      case "income":
        return classes.dollarSign;
      default:
        return "";
    }
  }

  return (
    <div className={classes.group}>
      <p>{props.group_title}</p>
      {questions.map((q) => {
        let answer = q.answers.length ? q.answers[0].value : "";
        return (
          <div
            key={q.id.toString()}
            className={getDecoratorClass(q.question_parameter)}
          >
            <TextField
              id={q.id.toString()}
              margin="normal"
              variant="outlined"
              fullWidth={true}
              type={inferInputType(q.question_parameter)}
              defaultValue={answer}
              placeholder={q.placeholder}
              required={q.required}
              // error
              // helperText="Error helper text"
              onChange={handleChange}
              className={classes.textField}
              label={q.label}
              inputProps={{ maxLength: 250 }}
              multiline={props.groupType === "text_area"}
              rows={props.groupType === "text_area" ? 8 : 1}
            />
          </div>
        );
      })}
    </div>
  );
}

export default TextInputGroup;
